import { navigate } from "gatsby";
import { useState, useEffect, useCallback } from "react";
import axios, { isAxiosError } from "axios";
import { jwtDecode } from "jwt-decode";

import useCookie from "./cookieHook";
import useLocalStorage from "./localStorageHook";

const validate = async (
  accessToken: string,
  impersonationSubject: string | null,
  baseURL: string,
) => {
  try {
    const headers = {
      "northstar-authorization": `Bearer ${accessToken}`,
      "northstar-identity": `okta`,
    };

    if (impersonationSubject)
      headers["northstar-impersonate"] = impersonationSubject;

    const { data } = await axios.get(
      new URL("/api/auth/whoami", baseURL).href,
      { headers },
    );

    return data;
  } catch (err) {
    if (!isAxiosError) throw err;

    return null;
  }
};

interface IOktaRefreshTokenExchangeResponse {
  refresh_token: string;
  access_token: string;
  id_token: string;
}

interface IAuthenticationResponse {
  refreshToken?: string;
  accessToken?: string;
  me?: Object;
}

const exchange = async (
  refreshToken: string,
  clientId: string,
  baseURL: string,
): Promise<IOktaRefreshTokenExchangeResponse | null> => {
  const params = new URLSearchParams();

  params.append("grant_type", "refresh_token");
  params.append("scope", "offline_access openid");
  params.append("refresh_token", refreshToken);
  params.append("client_id", clientId);

  const {
    data: { refresh_token, access_token, id_token },
  } = await axios.post(new URL("/oauth2/v1/token", baseURL).href, params);

  return { refresh_token, access_token, id_token };
};

const authenticate = async (
  clientId: string,
  accessToken: string | null,
  refreshToken: string | null,
  impersonationSubject: string | null,
  oktaURL: string,
  apiURL: string,
): Promise<IAuthenticationResponse> => {
  if (!refreshToken) return {};

  let validationResponse = null;

  if (accessToken)
    validationResponse = await validate(
      accessToken,
      impersonationSubject,
      apiURL,
    );

  if (!accessToken || validationResponse == null) {
    const exchangeResponse = await exchange(refreshToken, clientId, oktaURL);

    if (exchangeResponse == null) {
      return {};
    } else {
      const validationResponse = await validate(
        exchangeResponse.access_token,
        impersonationSubject,
        apiURL,
      );

      return {
        me: validationResponse,
        accessToken: exchangeResponse.access_token,
        refreshToken: exchangeResponse.refresh_token,
      };
    }
  }

  return { me: validationResponse, accessToken, refreshToken };
};

export default function useOktaIdentity(
  apiURL: string = process.env.GATSBY_API_URL,
  oktaURL: string = `https://${process.env.GATSBY_OKTA_DOMAIN}`,
  oktaClientId: string = process.env.GATSBY_OKTA_CLIENT_ID,
) {
  const [impersonation, setImpersonation, clearImpersonation] = useCookie(
    "northstar-impersonate",
    true,
    31_536_000, // Make the impersonation cookie very long-lived.
  );
  const [accessToken, setAccessToken, clearAccessToken] = useCookie(
    "northstar-authorization",
  );
  const [refreshToken, setRefreshToken, clearRefreshToken] = useLocalStorage(
    "northstar-refresh-token",
  );

  const [me, setMe] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // Token refresh logic
  useEffect(() => {
    const refreshTokenIfNeeded = async () => {
      if (!refreshToken) return;

      const refreshTime = getTokenExpiry(accessToken); // Defaults to 5 minutes adjustment
      if (!refreshTime) return; // Exit if there's no valid refresh time

      const currentTime = Date.now();

      if (currentTime >= refreshTime) {
        // Refresh token 5 minutes before expiry
        try {
          const response = await exchange(refreshToken, oktaClientId, oktaURL);
          if (response) {
            const { access_token, refresh_token } = response;
            setAccessToken(access_token);
            setRefreshToken(refresh_token);

            // Optionally, re-validate user identity
            const validationResponse = await validate(
              access_token,
              impersonation,
              apiURL,
            );
            setMe(validationResponse);
          }
        } catch (error) {
          clearAccessToken();
          clearRefreshToken();
          setMe(null);
          navigate("/error");
        }
      }
    };

    const intervalId = setInterval(refreshTokenIfNeeded, 5 * 60 * 1000); // Check every 5 minutes

    return () => clearInterval(intervalId);
  }, [accessToken, refreshToken]);

  useEffect(() => {
    let ignore = false;

    validate(accessToken, impersonation, apiURL).then((response) => {
      if (!ignore) setMe(response);
    });

    return () => {
      ignore = true;
    };
  }, [impersonation]);

  useEffect(() => {
    if (!refreshToken) return;

    setIsLoading(true);

    let ignore = false;

    authenticate(
      oktaClientId,
      accessToken,
      refreshToken,
      impersonation,
      oktaURL,
      apiURL,
    )
      .catch((error) => {
        setIsLoading(false);
        clearAccessToken();
        clearRefreshToken();
        setMe(null);

        if (!axios.isAxiosError(error)) {
          navigate("/error");
        }
      })
      .then((response) => {
        if (!ignore) {
          setIsLoading(false);

          const { me, accessToken, refreshToken } = response;

          setMe(me);
          setAccessToken(accessToken);
          setRefreshToken(refreshToken);
        }
      });

    return () => {
      ignore = true;
    };
  }, [accessToken, refreshToken]);

  /**
   * TODO: We should set an invalidation endpoint on the backend to
   * handle token invalidation.
   */
  const clearIdentity = useCallback(() => {
    clearAccessToken();
    setMe(null);
  });

  const logout = useCallback(() => {
    clearAccessToken();
    clearRefreshToken();
    setMe(null);
  });

  return {
    whoami: me,
    accessToken: accessToken,
    refreshToken,
    clearIdentity,
    setImpersonation,
    clearImpersonation,
    isLoading,
    logout,
    setAccessToken,
    setRefreshToken,
  };
}

interface JwtPayload {
  exp: number; // Expiry time in seconds
}

const getTokenExpiry = (
  token: string | null,
  timeBeforeExpiry: number = 5 * 60 * 1000,
): number | null => {
  if (!token) {
    return null;
  }

  try {
    const decoded = jwtDecode<JwtPayload>(token);
    const expiryInMillis = decoded.exp * 1000; // Convert seconds to milliseconds
    return expiryInMillis - timeBeforeExpiry; // Adjust by specified time before expiry
  } catch (error) {
    return null;
  }
};
